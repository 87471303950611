<template>
  <div class="code_editor"
    id="codeEditor"
    ref="codeEditor"
    :style="{
      height: this.editorHeight
    }">
  </div>
</template>
<script>
import * as monaco from 'monaco-editor'
export default {
  data () {
    return {
      // 编辑器配置
      options: {
        // 只读
        readOnly: false,
        // 光标样式
        cursorStyle: 'line',
        // 光标闪烁
        cursorBlinking: 'blink',
        // 自动布局
        automaticLayout: false,
        // 字体
        fontFamily: 'PingFang SC',
        // 行高
        lineHeight: 22,
        // 字体大小
        fontSize: 12,
        autoIndent: true,
        // 滚动条
        scrollbar: {
          useShadows: false,
          vertical: 'visible',
          horizontal: 'visible',
          horizontalSliderSize: 5,
          verticalSliderSize: 5,
          horizontalScrollbarSize: 10,
          verticalScrollbarSize: 10
        },
        // 代码缩略图
        minimap: {
          // 是否启用
          enabled: false
        }
      }
    }
  },
  props: {
    // 代码内容
    codes: {
      type: String,
      default: ''
    },
    // 语言
    language: {
      type: String,
      default: 'sql'
    },
    // 主题
    theme: {
      type: String,
      default: 'vs-dark' // vs, hc-black, or vs-dark
    },
    // 编辑器配置项
    editorOptions: {
      type: Object,
      default: () => ({})
    },
    // 编辑器高
    editorHeight: {
      type: String,
      default: '300px'
    },
    // 自定义主题的方法（暂时不同）
    defineTheme: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    $_codes: {
      get () {
        return this.codes
      },
      set (newValue) {
        this.$emit('update:codes', newValue)
      }
    }
  },
  mounted () {
    // 挂载的时候去初始化
    this.initEditor()
  },
  methods: {
    // 初始化编辑器
    initEditor () {
      // 自定义主题 https://microsoft.github.io/monaco-editor/playground.html#customizing-the-appearence-exposed-colors
      monaco.editor.defineTheme('myTheme', {
        base: this.theme,
        inherit: true,
        // rules: [{ background: 'EDF9FA' }],
        rules: [],
        colors: {
          // 编辑器背景色
          'editor.background': '#030D17',
          // 当前高亮的行
          'editor.lineHighlightBackground': '#2c313cd4' // #2C313C,
          // 'editor.selectionBackground': '#3b4048'
        }
      })

      this.monacoEditor = monaco.editor.create(this.$refs.codeEditor, {
        // 代码内容
        value: this.$_codes,
        // 语言
        language: this.language,
        // 主题
        // theme: this.theme, // vs, hc-black, or vs-dark
        theme: 'myTheme', // vs, hc-black, or vs-dark
        // 预定义的设置
        ...this.options,
        // 传入的编辑器配置项
        ...this.editorOptions
      })

      // 编辑器创建完成回调
      this.$emit('onEditorMounted', this.monacoEditor)

      // 编辑器内容changge事件 获取值
      this.monacoEditor.onDidChangeModelContent((event) => {
        this.$_codes = this.monacoEditor.getValue()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .code_editor {
    width: 100%;
  }
</style>
